import React, {createContext, useEffect, useState, useCallback} from 'react';
import { INotificacoes, NotificacoesService } from 'shared/services/api/notificacoes/Notificacoes';

export interface INotificacoesContextData {
  totalNotificacoes: number;
  notificacoes: INotificacoes[];
  getNotificacoes: () => void;
  carregandoNotif: boolean;
  setCarregandoNotif: (value: boolean) => void;
}

export const NotificacoesContext = createContext<INotificacoesContextData>(
  {} as INotificacoesContextData,
);

export const NotificacoesProvider: React.FC = ({children}) => {
  const [dispararRequisicao, setDispararRequisicao] = useState<boolean>(false);
  const [notificacoes, setNotificacoes] = useState<INotificacoes[]>([]);
  const [totalNotificacoes, setTotalNotificacoes] = useState(0);
  const [carregandoNotif, setCarregandoNotif] = useState(false);

  const getNotificacoes = useCallback(() => {
    setCarregandoNotif(true);
    NotificacoesService.getPublicoAlvoStatus().then((result) => {
      if(result.Success) {
        setNotificacoes(result.Data.reverse());
        setTotalNotificacoes(result.Data.length > 0 ? result.Data.length : 0);
      }
    }).finally(() => {
      setCarregandoNotif(false);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setDispararRequisicao(!dispararRequisicao);
      getNotificacoes();
    }, 60000);
  }, [dispararRequisicao, getNotificacoes]);

  return (
    <NotificacoesContext.Provider
      value={{totalNotificacoes: totalNotificacoes, notificacoes: notificacoes, getNotificacoes: getNotificacoes, carregandoNotif, setCarregandoNotif}}>
      {children}
    </NotificacoesContext.Provider>
  );
};
