import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IPublicoAlvoDetail {
  Id: string;
  Usuario: string;
  Nome: string;
  ErpFonte: string;
  PermiteEdicao: boolean;
  PublicoSemDisciplinas: boolean;
  ReprocessamentoAutomatico: boolean;
  RecargaAutomatica: boolean;
  Estabelecimentos: string[];
  IdsGruposPapeis: string[];
}

const getById = async (
  id: string,
): Promise<IResultOfAction<IPublicoAlvoDetail>> => {
  try {
    const {data, headers} = await Api.get(`/PublicosAlvo/${id}`);

    const ERPfonte = headers.erpintegracao;

    const IdsGruposPapeis = data.GruposPapeis.map(
      (papel) => papel.IdGrupoPapel,
    );

    const publicoAlvo: IPublicoAlvoDetail = {
      ErpFonte: ERPfonte,
      Id: data.Id,
      Nome: data.Nome,
      Estabelecimentos: data.Estabelecimentos.map(
        (estab) => estab.CodigoEstruturado,
      ),
      IdsGruposPapeis: IdsGruposPapeis,
      PermiteEdicao: data.PermiteEdicao,
      RecargaAutomatica: data.RecargaAutomatica,
      ReprocessamentoAutomatico: data.ReprocessamentoAutomatico,
      PublicoSemDisciplinas: data.PublicoSemDisciplinas,
    } as unknown as IPublicoAlvoDetail;

    const response: IResultOfAction<IPublicoAlvoDetail> = {
      Success: true,
      Message: '',
      Data: publicoAlvo,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const post = async (
  publicoAlvo: IPublicoAlvoDetail,
): Promise<IResultOfAction<IPublicoAlvoDetail>> => {
  try {
    const {data} = await Api.post('/PublicosAlvo', publicoAlvo);

    return data;
  } catch (error: any) {
    throw error.data.data;
  }
};

const put = async (
  publicoAlvo: IPublicoAlvoDetail,
): Promise<IResultOfAction<IPublicoAlvoDetail>> => {
  try {
    const {data} = await Api.put(
      `/PublicosAlvo/${publicoAlvo.Id}`,
      publicoAlvo,
    );

    return data;
  } catch (error: any) {
    throw error.data.data;
  }
};

const startProcessing = async (
  idPublicoAlvo: string,
): Promise<IResultOfAction<IPublicoAlvoDetail>> => {
  try {
    const {data} = await Api.put(
      `/PublicosAlvo/${idPublicoAlvo}/AlterarStatusAguardandoProcessamento`,
      {
        Id: idPublicoAlvo,
      },
    );

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const PublicoAlvoDetailsService = {
  post,
  put,
  getById,
  startProcessing,
};
